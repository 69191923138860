import { ITextCard } from "../../types/ITextCard";
import Container from "../Main/BlockElement/Container/Container";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";

export default function TextCard({ title, description }: ITextCard) {
  return (
    <Container className="flex flex-col md:w-1/3 px-10 md:py-32 md:my-10">
      <Container>
        <Heading type="h2" className="font-playfair text-xl md:text-3xl font-medium" items={[]} htmlFor="">{title}</Heading>
        <Paragraph className="font-playfair text-sm md:text-lg pt-5 " items={[]} htmlFor="">{description}</Paragraph>
      </Container>
    </Container>
  );
}
