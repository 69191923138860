import React, { Fragment } from "react";
import "./Page.scss";
import { IStructureElement } from "../../../../types/IStructureElement";
import Navbar from "../Navbar/Navbar";
import Footbar from "../Footbar/Footbar";
import Main from "../../BlockElement/Main/Main";

export default function Page({ children, className }: IStructureElement) {
  return (
    <Fragment>
      <Navbar />
      <Main className={"min-h-[calc(100vh-255px)] " + className}>
        {children}
      </Main>
      <Footbar />
    </Fragment>
  );
}
