import { IHeadline } from "../../types/IHeadline";
import Container from "../Main/BlockElement/Container/Container";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";

export default function Headline({ title, content }: IHeadline) {
  return (
    <Container className="flex items-center justify-center pt-[90px] pb-[15px]">
      <Container className="flex flex-col justify-center">
        <Heading type="h2" className="font-playfair md:text-4xl text-2xl text-sp-black text-center font-medium uppercase" items={[]} htmlFor="">{title}</Heading>
        <Paragraph className="font-playfair md:text-xl text-sm text-sp-black text-center pt-5 whitespace-pre-wrap" items={[]} htmlFor="">{content}</Paragraph>
      </Container>
    </Container>
  );
}
