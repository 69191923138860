import Container from "../Main/BlockElement/Container/Container";
import Heading from "../Main/TextElement/Heading/Heading";
import Label from "../Main/TextElement/Label/Label";
import { Button } from "../Main/FormElement/Button";
import { useMail } from "../../hooks/useMail";

export default function ContactForm(){
    const {
        company, setCompany,
        email, setEmail,
        phone, setPhone,
        messageContent, setMessageContent,
        isStatute, handleStatuteToggle,
        isMarketing, handleMarketingToggle,
        loading,
        handleSubmit,
    } = useMail();

    return(
        <form className="flex flex-col w-full md:w-4/12 gap-1 items-start bg-sp-light-pink mt-10 md:mr-8 p-[30px] md:absolute md:right-0">
            <Heading type="h2" items={[]} htmlFor="" className="font-playfair text-2xl text-sp-black font-regular uppercase">Formularz Kontaktowy</Heading>
            
            <Container className="w-full flex flex-col gap-2 mt-5">
                <Label items={[]} htmlFor="company" className="font-playfair text-lg text-sp-black">Nazwa firmy</Label>
                <input name="company" type="text" value={company} onChange={(e) => setCompany(e.target.value)} className="w-full p-2" />
            </Container>

            <Container className="w-full flex flex-col gap-2 mt-5">
                <Label items={[]} htmlFor="email" className="font-playfair text-lg text-sp-black">E-mail</Label>
                <input name="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full p-2" />
            </Container>

            <Container className="w-full flex flex-col gap-2 mt-5">
                <Label items={[]} htmlFor="phone" className="font-playfair text-lg text-sp-black">Numer telefonu</Label>
                <input name="phone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="w-full p-2" />
            </Container>

            <Container className="w-full flex flex-col gap-2 mt-5">
                <Label items={[]} htmlFor="mess" className="font-playfair text-lg text-sp-black">Treść wiadomości</Label>
                <textarea name="mess" value={messageContent} onChange={(e) => setMessageContent(e.target.value)} className="w-full p-2 min-h-[200px]"></textarea>
            </Container>

            <Container className="w-full flex flex-row items-start gap-4 mt-5">
                <input name="isStatute" type="checkbox" checked={isStatute} onClick={handleStatuteToggle} className="mt-2" />
                <Label items={[]} htmlFor="isStatute" className="font-playfair text-md text-sp-black">
                    Zapoznałem się z regulaminem sweetprofessional.pl oraz polityką prywatności i akceptuję ich treść.*
                </Label>
            </Container>

            <Container className="w-full flex flex-row items-start gap-4 mt-5">
                <input name="isMarketing" type="checkbox" checked={isMarketing} onClick={handleMarketingToggle} className="mt-2" />
                <Label items={[]} htmlFor="isMarketing" className="font-playfair text-md text-sp-black">
                    Chcę otrzymywać od sweetprofessional.pl na podany przeze mnie adres 
                    e-mail, informacje marketingowe dotyczące sweetprofessional.pl
                </Label>
            </Container>

            <Container className="w-full flex justify-end mt-6">
                <Button onClick={handleSubmit} className='bg-sp-sweet-pink text-white hover:bg-opacity-90 border-none py-3'>{loading ? "Wysyłanie..." : "Wyślij"}</Button>
            </Container>
        </form>
    );
}