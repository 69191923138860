import Page from "../../components/Main/StructureElement/Page/Page";
import { us } from "../../utils/us";
import Container from "../../components/Main/BlockElement/Container/Container";
import Us from "../../components/Us/Us";
import About from "../../components/About/About";
import Heading from "../../components/Main/TextElement/Heading/Heading";
import Paragraph from "../../components/Main/TextElement/Paragraph/Paragraph";
import JoinUs from "../../components/JoinUs/JoinUs";
import Animation from "../../components/Animation/Animation";

export default function AboutUs() {
  const keys = Object.keys(us);

  const header = (
    <>
      <Heading type="h2" items={[]} htmlFor={""} className="font-playfair text-xl md:text-4xl text-sp-black text-center font-medium">WARTOŚCI FIRMY I NASZE ZAANGAŻOWANIE</Heading>
      <Paragraph items={[]} htmlFor={""} className="font-playfair text-sm md:text-xl text-sp-black text-center pt-5">
        Tak jak wspomnieliśmy, w naszych działaniach dążymy do
        profesjonalizmu i wkładamy całe serce we wszystko co robimy. Poniżej
        przedstawiamy Ci nasze wartości, które są naszymi fundamentami w
        każdych działaniach.
      </Paragraph>
    </>
  );

  return (
    <Page className="pt-[100px] w-full bg-white">
      <Us />

      {keys.slice(0,2).map((key) => (
        <Animation>
          <Container className="py-10 px-5 md:px-0">
            <About key={key} {...us[key]} />
          </Container>
        </Animation>
      ))}

      <Container className="flex flex-col justify-center w-2/3 mx-auto py-10">{header}</Container>

      <Container className="w-4/5 mx-auto">
        {keys.slice(2, -1).map((key) => (
          <Animation>
            <Container className="py-10">
              <About key={key} {...us[key]} />
            </Container>
          </Animation>
        ))}
      </Container>

      <Animation>
        <Container className="pt-[75px] md:pt-[150px] px-5 md:px-0 bg-gradient-to-t from-sp-light-pink">
          <About {...us[keys[keys.length - 1]]} />
        </Container>
      </Animation>
      
      <JoinUs
        title="DOŁĄCZ DO ŚWIATA SWEET PROFESSIONAL"
        content={`Zarejestruj swój salon i uzyskaj dostęp do Akademii \nSweet Professional z naszymi autorskimi kursami \ni możliwością zakupu produktów.`}
        primaryColor="sp-sweet-pink"
        secondaryColor="sp-light-pink"
      />
      
      <Container className="md:mt-64 "></Container>
    </Page>
  );
}
