import blog1 from "../assets/blog/blog1.png";
import blog2 from "../assets/blog/blog2.png";
import blog3 from "../assets/blog/blog3.png";
import { ICard } from "../types/ICard";

export const blogs: ICard[] = [
    {
      img: blog1,
      title: "Efekty kuracji SOS",
      content:
        "Odkryj transformujące rezultaty kuracji SOS marki Sweet Professional, która zmienia zasady gry w zakresie rewitalizacji i przywracania włosom zdrowia i blasku.",
      href: "https://google.com",
    },
    {
      img: blog2,
      title: "Dzień Kobiet",
      content:
        "Świętuj siłę, osiągnięcia i piękno kobiet na całym świecie dzięki inspirującym historiom i szczerym hołdom. Wszystkiego najlepszego z okazji Dnia Kobiet!",
      href: "https://google.com",
    },
    {
      img: blog3,
      title: "Jak osiągnąć perfekcyjny blond?",
      content:
        "Odkryj sekrety oszałamiających blond włosów dzięki poradom ekspertów i wskazówkom krok po kroku, aby bez wysiłku osiągnąć wymarzony wygląd.",
      href: "https://google.com",
    },
];