export default function Arrows (props: {color: string, size?: string}) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={props.size ?? "29"}
                viewBox="0 0 17 29"
                width={props.size ?? "17"}
                color={props.color ?? "#cccccc"}
                fill={"none"}
            >

                <path d="M15.5 27L3 14.5L15.5 2" stroke={props.color} strokeWidth="3"/>

            </svg>
            &nbsp;
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height={props.size ?? "29"}
                viewBox="0 0 17 29"
                width={props.size ?? "17"}
                color={props.color ?? "#cccccc"}
                fill={"none"}
            >
                <path d="M1.5 2L14 14.5L1.5 27" stroke={props.color} strokeWidth="3"/>
            </svg>
        </>
    );
}
