import "./Footer.scss";
import { IBlockElement } from "../../../../types/IBlockElement";

export default function Footer({
  id,
  className,
  children,
  onClick,
  onChange,
}: IBlockElement) {
  return (
    <footer id={id} className={className} onClick={onClick} onChange={onChange}>
      {children}
    </footer>
  );
}
