import React from "react";
import "./Button.scss";
import { IFormElement } from "../../../../types/IFormElement";

export default function Button({
  id,
  className,
  children,
  onClick,
}: IFormElement) {
  return (
    <button
      id={id}
      className={`${className} border font-lato rounded rounded-3xl p-[10px] px-[40px] hover:shadow-lg transform transition duration-900 ease-in-out`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
