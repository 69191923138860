import ContactForm from "../ContactForm/ContactForm";
import Details from "../Details/Details";
import Section from "../Main/BlockElement/Section/Section";

export default function ContactFormAndDetails(){
    return(
        <Section className="flex flex-col md:flex-row justify-between w-full mt-[180px] md:mt-20 md:mb-[400px]">
            <Details />
            <ContactForm />
        </Section>
    );
}