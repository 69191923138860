import { IProduct } from "../types/IProduct";
import header from '../assets/products/cronology.png';
import proteins from '../assets/products/lines/cronology/proteiny.jpg';
import aminokwasy from '../assets/products/lines/cronology/aminokwasy.jpg';
import chlorofil from '../assets/products/lines/cronology/chlorofil.jpg';
import odzywkacream from '../assets/products/lines/cronology/odzywkacream.jpg';
import odzywkawlosycialo from '../assets/products/lines/cronology/odzywkawlosycialo.jpg';
import before from '../assets/products/lines/cronology/Cronology1.jpg';
import after from '../assets/products/lines/cronology/Cronology2.jpg';
import lamelarny from '../assets/products/lines/cronology/lamelarny.jpg';
import szamponmolecular from '../assets/products/lines/cronology/szamponmolecular.jpg';
import szampon from '../assets/products/lines/cronology/szampon.jpg';
import { Star } from "../components/Product/ProductIcon/Star/Star";
import { ReactNode } from "react";

export const data: IProduct = {
    header: {
        image: header,
        name: "Cronology",
        nameDescription: "Biotechnologia",
        description:
            "Pierwszy na świecie inteligentny system skanowania włosów, który uzupełnia ich strukturę o brakujące składniki.",
        primaryColor: "sp-crono-primary",
        secondaryColor: "sp-crono-secondary",
        circle: {
            color: "#A4863F",
            icon: <Star color={"#A4863F"}/>,
            text1: (
                <>
                    BIOTECHNOLOGICZNE
                    <br/>
                    BADANIE STRUKTURY
                </>
            ) as unknown as ReactNode,
            text2: (
                <>
                    KOMPLEKSOWE
                    <br/>
                    ODŻYWIENIE
                </>
            ) as unknown as ReactNode,
            text3: (
                <>
                    NATURALNE
                    <br/>
                    SKŁADNIKI AKTYWNE
                </>
            ) as unknown as ReactNode,
        },
    },
    line: {
        title: "",
        description: "",
        additionalDescription: "",
        primaryColor: "",
        secondaryColor: "",
        product1: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
        product2: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
        product3: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
    },
    productsFormula: {
        ingredients: [
            {
                image: chlorofil,
                name: "Chlorofil",
            },
            {
                image: proteins,
                name: "Proteiny",
            },
            {
                image: aminokwasy,
                name: "Aminokwasy",
            },
            {
                image: lamelarny,
                name: "Żel lamelarny",
            },
        ],
    },
    comparison: {
        title: "EFEKTY STOSOWANIA",
        content:
            `Modelka miała problem z przesuszoną skórą głowy i jednocześnie bardzo przetłuszczającą się. Mierzyła \nsię z łupieżem, zaczerwienieniami, oraz swędzeniem i pieczeniem. Już po pierwszym użyciu nieprzyjemne dolegliwości zostały załagodzone, a zaczerwienienia i suche plamy znacznie zmalały.`,
        imageBefore: before,
        imageAfter: after,
        primaryColor: "sp-crono-primary",
        secondaryColor: "sp-crono-secondary",
        arrowsColor: "#EDE7D9",
    },
    joinUs: {
        title: "CHCESZ MIEĆ LINIĘ CRONOLOGY W SWOIM SALONIE?",
        content:
            "Zarejestruj swój salon i uzyskaj możliwość zakupu produktów oraz dostęp do Akademii Sweet Professional z naszymi autorskimi kursami.",
        primaryColor: "sp-crono-primary",
        secondaryColor: "sp-crono-secondary",
    },
    productsHairdresser: {
        title: (<span className="text-xl md:text-4xl">PRODUKTY DO SALONÓW</span>) as unknown as ReactNode,
        description:
            "Nasze produkty są przeznaczone dla profesjonalistów, dlatego nabyć je mogą jedynie salony fryzjerskie.",
        additionalDescription: "",
        primaryColor: "sp-crono-primary",
        secondaryColor: "sp-crono-secondary",
        descriptionPosition: "before",
        products: [
            {
                image: szamponmolecular,
                title: "Szampon Molecular Renewal",
                special: false,
                description:
                    "Wysoko nawilżający szampon z właściwościami stymulującymi odbudowę na poziomi komórkowym. Skanuje łuskę włosa i uzupełnia ją. Reguluje wydzielanie sebum i wzmacnia włosy.",
                capacity: "980ml",
                primaryColor: "sp-crono-primary",
            },
            {
                image: odzywkacream,
                title: "Odżywka Hair Cream",
                special: false,
                description:
                    "Skanuje włókno włosa i rekonstruuje go zarówno od wewnątrz i od zewnątrz. Inteligentne i aktywne składniki skomponowane zostały z 70 typów keratyny i 5 typów kolagenu. Wzmacnia, odbudowuje i uzupełnia masę włosów.",
                capacity: "980g",
                primaryColor: "sp-crono-primary",
            },
        ],
    },
    productsClient: {
        title: (<span className="text-xl md:text-4xl">PRODUKTY DLA KLIENTEK</span>) as unknown as ReactNode,
        description:
            "Część produktów z linii jest dostosowana do pielęgnacji domowej. Można je zakupić w naszych salonach partnerskich. Dzięki temu każdy może w kompleksowy sposób zadbać o swoje włosy.",
        additionalDescription: "",
        primaryColor: "sp-crono-primary",
        secondaryColor: "sp-crono-secondary",
        descriptionPosition: "after",
        products: [
            {
                image: szampon,
                title: "Szampon",
                special: false,
                description:
                    "Płyn do demakijażu i szampon z biotechnologią, która naprawia uwrażliwione włosy, skanując łuskę włosa, oraz delikatnie usuwa makijaż dzięki bezolejowej formule. Nawilża skórę i nie zatyka porów.",
                capacity: "230ml",
                primaryColor: "sp-crono-primary",
            },
            {
                image: odzywkawlosycialo,
                title: "Odżywka do włosów i ciała",
                special: false,
                description:
                    "Intensywnie odżywia włosy. Ma jedwabistą konsystencję. Skanuje łuskę włosa i stymuluje ją do odbudowy. Może być stosowana jako krem nawilżający do twarzy i balsam do ciała.",
                capacity: "230g",
                primaryColor: "sp-crono-primary",
            },
        ],
    },
};
