import Animation from "../Animation/Animation";
import Section from "../Main/BlockElement/Section/Section";
import Headline from "../Headline/Headline";
import { Button } from "../Main/FormElement/Button";
import Container from "../Main/BlockElement/Container/Container";
import { blogs } from "../../utils/blogs";
import Card from "../Card/Card";

export default function BlogShort() {

  const header = (
    <Container className="w-full px-8">
      <Headline title="Odkryj Sekrety Pięknych Włosów" content={`Zanurz się w świecie fryzjerstwa i pielęgnacji włosów dzięki naszym eksperckim artykułom. Poznaj najnowsze trendy, \nsprawdzone techniki i porady, które pomogą Ci zadbać o włosy jak profesjonalista.`}/>
      <Container className="flex items-center justify-center">
        <Button className="border-sp-dark hover:bg-sp-sweet-pink hover:text-white hover:border-sp-sweet-pink">Odkryj teraz</Button>
      </Container>
    </Container>
  );

  const cards = (
    <Container className="flex flex-wrap justify-center">
      {blogs.map((el) => (
        <Container className="md:w-1/3 px-10 pt-10">
          <Card {...el} />
        </Container>
      ))}
    </Container>
  );

  return(
    <Animation>
      <Section>
        <Container className="flex justify-center">{header}</Container>
        <Container className="px-10 pb-10">{cards}</Container>
      </Section>
    </Animation>
  );
}
