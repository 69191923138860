import { useState } from "react";
import { useApi } from "./useApi";
import { useAsync } from "./useAsync";
import { message } from "antd";

export const useMail = () => {
    const [company, setCompany] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [messageContent, setMessageContent] = useState<string>('');
    const [isStatute, setIsStatute] = useState<boolean>(false);
    const [isMarketing, setIsMarketing] = useState<boolean>(false);
    const { api } = useApi();

    const { loading, execute } = useAsync(async () => {
        const payload = { company, email, phone, message: messageContent };
        return await api.post<void>("/api/public/mail", payload);
    });

    const handleStatuteToggle = () => setIsStatute(!isStatute);
    const handleMarketingToggle = () => setIsMarketing(!isMarketing);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!isStatute) {
            message.warning("Please accept the terms and conditions.");
            return;
        }
        try {
            await execute();
            message.success("Your message has been successfully sent.");
            resetForm();
        } catch (error) {
            message.error("An error occurred while sending your message. Please try again.");
        }
    };

    const resetForm = () => {
        setCompany('');
        setEmail('');
        setPhone('');
        setMessageContent('');
        setIsStatute(false);
        setIsMarketing(false);
    };

    return {
        company, setCompany,
        email, setEmail,
        phone, setPhone,
        messageContent, setMessageContent,
        isStatute, handleStatuteToggle,
        isMarketing, handleMarketingToggle,
        loading,
        handleSubmit,
    };
};