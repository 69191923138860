import Container from "../../BlockElement/Container/Container";
import { Link } from "react-router-dom";
import logo from "../../../../assets/logo.svg";
import close from "../../../../assets/close.png";
import Image from "../../MediaElement/Image/Image";
import { useState } from "react";
import navigationItems from "../../../../utils/navigationItems";
import Heading from "../../TextElement/Heading/Heading";
import Nav from "../../BlockElement/Nav/Nav";
import arrowRight from "../../../../assets/arrowRight.png";
import arrowLeft from "../../../../assets/arrowLeft.png";
import menu from "../../../../assets/menu.png";
export default function NavbarMobile() {
  const [isVisibleMenuFirst, setIsVisibleMenuFirst] = useState(false);

  const toggleVisibilityMenuFirst = () => {
    setIsVisibleMenuFirst(!isVisibleMenuFirst);
  };

  const [isVisibleMenuSecond, setIsVisibleMenuSecond] = useState(false);

  const toggleVisibilityMenuSecond = () => {
    setIsVisibleMenuSecond(!isVisibleMenuSecond);
  };

  const navigationItemsSecond = navigationItems.filter((el) => el.children)[0]
    .children;

  return (
    <Nav>
      <Container className="flex justify-between items-center bg-sp-light-pink p-3 px-4">
        <Container onClick={toggleVisibilityMenuFirst}>
          <Image src={menu}></Image>
        </Container>
        <Link to="/">
          <Image src={logo} className="h-[45px]" />
        </Link>
        {/* Menu 1 */}
        <Container
          className={`fixed top-0 left-0 h-full w-2/3 bg-sp-light-pink p-4 transition-transform duration-300 border border-sp-dark z-50 ${
            isVisibleMenuFirst ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <Container className="flex justify-between items-center">
            <Link to={"/"}>
              <Image
                src={logo}
                className="h-[45px]"
                onClick={toggleVisibilityMenuFirst}
              />
            </Link>
            <Image src={close} onClick={toggleVisibilityMenuFirst} />
          </Container>

          <Container className="pt-5">
            {navigationItems.map((item) => (
              <Container key={item.title} className="pt-5">
                <Container className="flex justify-between">
                  <Heading
                    type="h1"
                    htmlFor=""
                    items={[]}
                    className="pl-3 text-xl font-lato"
                  >
                    <Link to={item.href ?? ""}>{item.title}</Link>
                  </Heading>
                  {item.children ? (
                    <Image
                      src={arrowRight}
                      onClick={() => {
                        toggleVisibilityMenuSecond();
                      }}
                    />
                  ) : null}
                </Container>
                <Container className="bg-black h-[2px] my-2" />
              </Container>
            ))}
          </Container>
        </Container>
        {/* Menu 2 */}
        <Container
          className={`fixed top-0 left-0 h-full w-2/3 bg-sp-light-pink p-4 transition-transform duration-300 border border-sp-dark z-50 ${
            isVisibleMenuSecond ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <Container className="flex">
            <Container className="flex" onClick={toggleVisibilityMenuSecond}>
              <Image src={arrowLeft} />
              <Heading
                type="h1"
                htmlFor=""
                items={[]}
                className="pl-3 text-xl font-lato "
              >
                Produkty
              </Heading>
            </Container>

            <Image
              src={close}
              onClick={() => {
                toggleVisibilityMenuFirst();
                toggleVisibilityMenuSecond();
              }}
              className="ml-auto"
            />
          </Container>

          <Container className="pt-5">
            {navigationItemsSecond ? (
              <Container>
                {navigationItemsSecond.map((item) => (
                  <Container key={item.title} className="pt-5">
                    <Container className="flex justify-between">
                      <Heading
                        type="h1"
                        htmlFor=""
                        items={[]}
                        className="pl-3 text-xl font-lato"
                      >
                        <Link to={item.href ?? ""}>{item.title}</Link>
                      </Heading>
                    </Container>
                    <Container className="bg-black h-[2px] my-2" />
                  </Container>
                ))}
              </Container>
            ) : null}
          </Container>
        </Container>
      </Container>
    </Nav>
  );
}
