import { contacts } from "../../utils/contacts";
import Detail from "../Detail/Detail";
import Container from "../Main/BlockElement/Container/Container";

export default function Details(){

    const upperRow = contacts.slice(0,2).map((element, index) => (
        <Detail key={index} {...element} />
    ));

    const lowerRow = contacts.slice(2).map((element, index) => (
        <Detail key={index} {...element} />
    ));

    return(
        <Container className="flex flex-col w-full md:w-7/12 border p-5 md:p-8 gap-8">
                <Container className="flex flex-row justify-center items-start w-full">{upperRow}</Container>
                <Container className="flex flex-row justify-center items-start w-full">{lowerRow}</Container>
        </Container>
    );
}