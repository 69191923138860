import { ReactNode } from "react";
import { IProduct } from "../types/IProduct";
import { SOSPlus } from "../components/Product/ProductIcon/SOSPlus/SOSPlus";
import header from '../assets/products/sos.jpg';
import aminokwasy from '../assets/products/lines/sos/aminokwasy.png';
import bondbuilderinstantrepair from '../assets/products/lines/sos/bondbuilderinstantrepair.png';
import bondbuildershampoo from '../assets/products/lines/sos/bondbuildershampoo.png';
import kolagen from '../assets/products/lines/sos/kolagen.png';
import odzywkaregen from '../assets/products/lines/sos/odzywkaregen.png';
import pca from '../assets/products/lines/sos/pca.png';
import powder from '../assets/products/lines/sos/powder.png';
import proteiny from '../assets/products/lines/sos/proteiny.png';
import before from '../assets/products/lines/sos/sos1.jpg';
import after from '../assets/products/lines/sos/sos2.jpg';
import szamponinstantrepair from '../assets/products/lines/sos/szamponinstantrepair.png';
import sprayimpactshock from '../assets/products/lines/sos/sprayimpactshock.png';
import sosbondbuilder from '../assets/products/lines/sos/sosbondbuilder.png';

export const data: IProduct = {
    header: {
        image: header,
        name: "S.O.S",
        nameDescription: "Na ratunek Twoim włosom",
        description: `System rekonstrukcji i odbudowy, wykorzystuje \nnowoczesną technologię do przywracania i poprawy \nstruktury uszkodzonych oraz maksymalnie \nuwrażliwionych włosów.`,
        primaryColor: "sp-sos-primary",
        secondaryColor: "sp-sos-secondary",
        circle: {
            color: "#AF168B",
            icon: <SOSPlus color={"#AF168B"}/>,
            text1: (
                <>
                    ODBUDOWA BARDZO
                    <br/>
                    ZNISZCZONYCH WŁOSÓW
                </>
            ) as unknown as ReactNode,
            text2: (
                <>
                    OCHRONA PRZED
                    <br/>
                    ZABIEGAMI CHEMICZNYMI
                </>
            ) as unknown as ReactNode,
            text3: (
                <>
                    ZAPOBIEGANIE
                    <br/>
                    KRUSZENIU
                </>
            ) as unknown as ReactNode,
        },
    },
    line: {
        title: "",
        description: "",
        additionalDescription: "",
        primaryColor: "",
        secondaryColor: "",
        product1: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
        product2: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
        product3: {
            image: "",
            title: "",
            special: false,
            description: "",
            capacity: "",
            primaryColor: "",
        },
    },
    productsFormula: {
        ingredients: [
            {
                image: kolagen,
                name: "Hydrolizowany kolagen",
            },
            {
                image: pca,
                name: "PCA-NA",
            },
            {
                image: proteiny,
                name: "Proteiny",
            },
            {
                image: aminokwasy,
                name: "Aminokwasy",
            },
        ],
    },
    comparison: {
        title: "EFEKTY STOSOWANIA",
        content:
            `Po regularnym rozjaśnianiu i nieodpowiedniej pielęgnacji domowej, włosy zostały skrajnie zniszczone, a modelka miała ogromny problem z ich układaniem. Dzięki zabiegowi \nz serią S.O.S, włosy zostały naprawione,  stały się sypkie, gładkie i przestały się kruszyć.`,
        imageBefore: before,
        imageAfter: after,
        primaryColor: "sp-sos-primary",
        secondaryColor: "sp-sos-secondary",
        arrowsColor: "#EDE7D9",
    },
    joinUs: {
        title: "CHCESZ MIEĆ LINIĘ S.O.S W SWOIM SALONIE?",
        content:
            "Zarejestruj swój salon i uzyskaj możliwość zakupu produktów oraz dostęp do Akademii Sweet Professional z naszymi autorskimi kursami.",
        primaryColor: "sp-sos-primary",
        secondaryColor: "sp-sos-secondary",
    },
    productsHairdresser: {
        title: (<span className="text-xl md:text-4xl">PRODUKTY DO SALONÓW</span>) as unknown as ReactNode,
        description:
            "Nasze produkty są przeznaczone dla profesjonalistów, dlatego nabyć je mogą jedynie salony fryzjerskie.",
        additionalDescription: "",
        primaryColor: "sp-sos-primary",
        secondaryColor: "sp-sos-secondary",
        descriptionPosition: "before",
        products: [
            {
                image: szamponinstantrepair,
                title: "Szampon Instant Repair",
                special: false,
                description:
                    "Uzupełnia proteiny, przywraca elastyczność, intensywnie naprawia. Neutralizuje alkaliczne procesy chemiczne.",
                capacity: "980ml",
                primaryColor: "sp-sos-primary",
            },
            {
                image: sprayimpactshock,
                title: "Spray Impact Shock",
                special: false,
                description:
                    "Formuła w sprayu. Naprawia włosy w zaledwie 10 minut! Zatrzymuje łamanie się włosów. Przywraca niezbędne aminokwasy do kory włosa. Neutralizuje alkaliczne procesy chemiczne oraz pomaga w rozplątywaniu. Zapewnia włosom elastyczność i sprężystość.",
                capacity: "980ml",
                primaryColor: "sp-sos-primary",
            },
            {
                image: odzywkaregen,
                title: "Odżywka Regeneration",
                special: false,
                description:
                    "Odżywia włosy, ma dużą moc kondycjonowania. Przywraca elastyczność i zapobiega elektryzowaniu się włosów. Chroni przed uszkodzeniami spowodowanymi wysokimi temperaturami.",
                capacity: "980ml",
                primaryColor: "sp-sos-primary",
            },
            {
                image: powder,
                title: "Powder",
                special: false,
                description:
                    "Technologia przewyższająca znane dotychczas plexy. Chroni skórę głowy oraz nie ingeruje w proces rozjaśniania. Jest to formuła w proszku. Nie ma więc wpływu na pH. Nie zmienia czasu rozjaśniania oraz nie ingeruje w jego proces, a jedynie towarzyszy mu z korzyścią dla włosów. Rozjaśniacz pobiera proteiny z produktu zamiast zabierać je włosom. ",
                capacity: "90g",
                primaryColor: "sp-sos-primary",
            },
            {
                image: bondbuilderinstantrepair,
                title: "Bond Builder Instant Repair",
                special: false,
                description:
                    "Uzupełnia masę włosa i odbudowuje jego włókno. Pomaga neutralizować zasadowość. Może być stosowany jako leave-in lub maska. Chroni przed wysoką temperaturą. Stosowany do zabiegów w salonie, ale również w domu.",
                capacity: "200ml",
                primaryColor: "sp-sos-primary",
            },
        ],
    },
    productsClient: {
        title: (<>PRODUKTY DLA KLIENTEK</>) as unknown as ReactNode,
        description:
            "Część produktów z linii jest dostosowana do pielęgnacji domowej. Można je zakupić w naszych salonach partnerskich. Dzięki temu każdy może w kompleksowy sposób zadbać o swoje włosy.",
        additionalDescription: "",
        primaryColor: "sp-sos-primary",
        secondaryColor: "sp-sos-secondary",
        descriptionPosition: "header",
        products: [
            {
                image: bondbuildershampoo,
                title: "Bond builder Shampoo",
                special: false,
                description:
                    "Jest stworzony do kontynuacji leczenia i pielęgnacji włosów. Szczególnie po zabiegu restrukturyzacji w salonie. Zapewni włosom zwiększony połysk, miękkość, łatwość układania. Pomoże zregenerować uszkodzone polipeptydy.",
                capacity: "230ml",
                primaryColor: "sp-sos-primary",
            },
            {
                image: bondbuilderinstantrepair,
                title: "Bond builder Instant repair",
                special: false,
                description:
                    "Uzupełnia masę włosa i odbudowuje jego włókno. Pomaga neutralizować zasadowość. Może być stosowany jako leave-in lub maska. Chroni przed wysoką temperaturą. Stosowany do zabiegów w salonie, ale również w domu.",
                capacity: "200ml",
                primaryColor: "sp-sos-primary",
            },
            {
                image: sosbondbuilder,
                title: "S.O.S Bond builder Conditioner",
                special: false,
                description:
                    "Wspiera proces restrukturyzacji zniszczonych włosów. Zapewnia ochronę. Włosy stają się bardziej miękkie, łatwiejsze do rozczesania i wysuszenia.",
                capacity: "230ml",
                primaryColor: "sp-sos-primary",
            },
        ],
    },
};
