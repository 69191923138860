import React from "react";
import { IJoinUs } from "../../types/IJoinUs";
import Animation from "../Animation/Animation";
import Section from "../Main/BlockElement/Section/Section";
import joinus from "../../assets/joinus/joinus.webp";
import Container from "../Main/BlockElement/Container/Container";
import Image from "../Main/MediaElement/Image/Image";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";
import { Button } from "../Main/FormElement/Button";

export default function JoinUs({ title, content, secondaryColor, primaryColor }: IJoinUs) {

  const picture = (
    <Container className="flex w-full">
      <Image src={joinus} className={"flex w-full relative left-3 top-3 md:left-32 md:top-32 "} />
    </Container>
  );

  const text = (
    <Container className="flex justify-center md:flex-row w-full">
      <Container className="md:ml-[20%] pb-8 flex flex flex-col items-stretch justify-center">
        <Heading type='h2' items={[]} htmlFor={""} className="font-playfair text-2xl md:text-4xl text-sp-black text-center md:text-left font-medium w-full">{title}</Heading>
        <Paragraph items={[]} htmlFor={""} className="whitespace-pre-wrap font-playfair text-sm md:text-xl font-light mt-5 font-light w-full text-center md:text-left whitespace-pre-wrap">
          {content}
          <br /><br />
          <Button className={`bg-${primaryColor} text-white hover:bg-opacity-90 border-none py-3`}>Zarejestruj się</Button>
        </Paragraph>
      </Container>
    </Container>
  );

  return(
    // <Animation>
      <Section className="mt-[50px] md:mb-[150px]">
        <Container className={"flex flex-col md:flex-row w-full sm:w-full pt-20 sm:pt-0 p-5"}>
          <Container className={`flex-col md:flex-row md:w-3/5 lg:w-3/5 sm:w-full md:h-full bg-${secondaryColor}`}>{picture}</Container>
          <Container className="md:w-1/12 w-full"></Container>
          <Container className="md:w-6/12 w-full md:relative md:top-44 md:mt-32 mt-10">{text}</Container>
        </Container>
      </Section>
    // </Animation>
  );
}
