import Container from "../Main/BlockElement/Container/Container";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";
import Image from "../Main/MediaElement/Image/Image";
import { ICard } from "../../types/ICard";
import Link from "../Main/MediaElement/Link/Link";

export default function Card({ img, title, content, href }: ICard) {
  return (
    <Link href={href}>
      <Container className="bg-gradient-to-t from-transparent to-sp-light-pink p-3 shadow-md shadow-gray-500/50 border hover:shadow-lg transform hover:translate-y-[0.60px] transition duration-900 ease-in-out">
        <Image src={img} alt={title} className="w-full" />
        <Container>
          <Heading type="h3" className="font-playfair md:text-2xl md:pl-2 text-md my-5" items={[]} htmlFor="">{title}</Heading>
          <Paragraph className="font-playfair text-xs md:text-lg md:pl-2" items={[]} htmlFor="">{content}</Paragraph>
        </Container>
      </Container>
    </Link>
  );
}
