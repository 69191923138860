import { IQuality } from "./../types/IQuality";
import first from "../assets/quality/img01.webp";
import second from "../assets/quality/img02.webp";
import third from "../assets/quality/img03.webp";

export const qualities: IQuality[] = [
  {
    img: first,
    title: "SKUTECZNOŚĆ",
    content: "Dzięki ponad 2000 testów przed wprowadzeniem na rynek.",
  },
  {
    img: second,
    title: "EKSKLUZYWNOŚĆ",
    content: "Jesteśmy jedynym oficjalnym importerem marki w Polsce. Chronimy jej ekskluzywności, dlatego produkty nie są w ogólnodostępnej sprzedaży internetowej.",
    paddingTop: true,
  },
  {
    img: third,
    title: "KOMPATYBILNOŚĆ",
    content: "Nasze produkty możesz bez problemu łączyć z innymi markami i używać ich jako termoochrona.",
  },
];  