import home from "../../assets/hero/home.webp";
import { useNavigate } from "react-router-dom";
import Section from "../Main/BlockElement/Section/Section";
import Container from "../Main/BlockElement/Container/Container";
import Image from "../Main/MediaElement/Image/Image";
import Heading from "../Main/TextElement/Heading/Heading";
import { Button } from "../Main/FormElement/Button";
import Headline from "../Headline/Headline";
import mobile from "../../assets/hero/mobile.webp";

export default function Hero() {
  const navigate = useNavigate();

  return (
    <Section>
      <Container className="relative bg-sp-light-gray">
        <Image src={home} alt="hero" className="relative object-cover pt-0 w-full hidden md:block"/>
        <Image src={mobile} alt="hero" className="relative object-cover pt-0 w-full block md:hidden"/>
        <Container className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-sp-light-pink w-full h-full flex justify-start md:justify-center md:items-center pt-10 md:pt-0 pl-2 md:pl-2">
          <Container className="md:w-1/3 md:ml-[60%] p-2">
            <Heading type="h1" className="font-playfair md:text-4xl text-2xl" items={[]} htmlFor="">Marka inna, dzięki <br /> nowoczesnej technologii <br /> i starannie dobranym <br /> składnikom</Heading>
            <Button className="mt-5 border-sp-sweet-pink text-sp-sweet-pink hover:bg-sp-sweet-pink hover:text-white" onClick={() => navigate(`/o-nas`)}>O nas</Button>
          </Container>
        </Container>
      </Container>
      <Container className="flex justify-center bg-sp-light-pink px-5 py-10">
        <Container className="md:w-3/5">
          <Headline title="SWEET TO STYL ŻYCIA" content={`Jesteśmy czymś więcej niż marką kosmetyczną, jesteśmy Twoją szansą, aby stać się przedsiębiorcą, \nprzekształcając i wzmacniając jeszcze bardziej naturalne piękno człowieka`}/>
        </Container>
      </Container>
    </Section>
  );
}
