import { useNavigate } from "react-router-dom";
import { lines } from "../../utils/lines";
import Animation from "../Animation/Animation";
import Container from "../Main/BlockElement/Container/Container";
import Headline from "../Headline/Headline";
import Section from "../Main/BlockElement/Section/Section";
import About from "../About/About";

export default function Lines() {
  const navigate = useNavigate();

  const header = (
    <Container className="bg-gradient-to-t from-sp-light-pink via-sp-light-pink px-8 md:pt-10 flex justify-center">
      <Headline
        title="NASZE LINIE KOSMETYKÓW"
        content={`Dzięki Sweet Professionals dogłębnie zadbasz o odbudowę struktury włosów swoich klientów. \nNawet tych najbardziej zniszczonych!`}
      />
    </Container>
  );

  const options = lines.map((product, index) => (
    <Animation>
      <Container className="pt-10 flex justify-center items-center">
        <About
          title={product.name}
          content={product.content}
          image={product.img}
          imgPosition={index % 2 === 0 ? "left" : "right"}
          onClick={() => navigate(`/produkty/${product.path}`)}
          textButton="Zobacz więcej"
        />
      </Container>
    </Animation>
  ));

  return (
    <Section className="md:pt-[50px]">
      <Animation>{header}</Animation>
      <Container className="md:pt-8 bg-gradient-to-b from-sp-light-pink">
        <Container className="w-4/5 mx-auto">
          <Container className="flex flex-col justify-center items-center">{options}</Container>
        </Container>
      </Container>
    </Section>
  );
}
