import Container from "../Main/BlockElement/Container/Container";
import Section from "../Main/BlockElement/Section/Section";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";
import SearchBar from "../SearchBar/SearchBar";

export default function BlogHeader(props: { phrase: string, setPhrase: (e: any) => void, search: () => void }) {
    return(
        <Section className="flex justify-center">
            <Container className="w-full md:w-5/6 flex flex-col items-center justify-center gap-5 py-[70px]">
                <Heading type="h1" className="font-playfair md:text-4xl text-2xl text-sp-black text-center font-medium uppercase" items={[]} htmlFor="">Blog</Heading>
                <Paragraph className="font-playfair md:text-xl text-sm text-sp-black text-center pt-5" items={[]} htmlFor="">
                    Zanurz się w świecie fryzjerstwa i pielęgnacji włosów dzięki naszym eksperckim artykułom. 
                    Poznaj najnowsze trendy, sprawdzone techniki i porady, które pomogą Ci zadbać o włosy jak profesjonalista.
                </Paragraph>
                <SearchBar phrase={props.phrase} setPhrase={props.setPhrase} search={props.search}/>
            </Container>
        </Section>
    );
}