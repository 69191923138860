import Container from "../Main/BlockElement/Container/Container";

export default function SearchBar(props: { phrase: string, setPhrase: (e: any) => void, search: () => void }){
    
    return(
        <Container className="w-5/6 md:w-1/2 flex justify-center items-center mt-10 p-2 border-b border-black">
            <input 
                type="text"
                placeholder="Wpisz szukane hasło" 
                value={props.phrase}
                onChange={(e) => props.setPhrase(e.target.value)}
                className="w-full focus:outline-none"
            />
            <span className="material-symbols-outlined cursor-pointer duration-200 ease-in-out hover:scale-110" onClick={props.search}>search</span>
        </Container>
    );
}