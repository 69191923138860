type NavigationLink = {
  title: string;
  href?: string;
};

type NavigationItem = NavigationLink & {
  children?: NavigationItem[];
};

const navigationItems: NavigationItem[] = [
  {
    title: "O nas",
    href: "/o-nas",
  },
  {
    title: "Produkty",
    children: [
      {
        title: "SOS",
        href: "/produkty/sos",
      },
      {
        title: "Cronology",
        href: "/produkty/cronology",
      },
      {
        title: "Detox",
        href: "/produkty/detox",
      },
      {
        title: "Alquimia",
        href: "/produkty/alquimia",
      },
      {
        title: "Lovely",
        href: "/produkty/lovely",
      },
      {
        title: "The First",
        href: "/produkty/the-first",
      },
    ],
  },
  {
    title: "Akademia",
    href: "/akademia",
  },
  // {
  //   title: "Salony Partnerskie",
  //   href: "/salony-partnerskie",
  // },
  {
    title: "Kontakt",
    href: "/kontakt",
  },
  {
    title: "Blog",
    href: "/blog",
  },
];

export default navigationItems;
