import sos from "../assets/SOS.webp";
import cronology from "../assets/Cronology_1.webp";
import detox from "../assets/Detox_1.webp";
import alquimia from "../assets/Aquimia.webp";
import thefirst from "../assets/The-First.webp";
import lovely from "../assets/Lovely.webp";
import { ILine } from "../types/ILine";

export const lines: ILine[] = [
  {
    img: sos,
    name: "SOS",
    path: "sos",
    content:
      `System rekonstrukcji i odbudowy, wykorzystuje \nnowoczesną technologię do przywracania \ni poprawy struktury uszkodzonych \noraz maksymalnie uwrażliwionych włosów.`,
  },
  {
    img: cronology,
    name: "CRONOLOGY",
    path: "cronology",
    content:
      "Pierwszy na świecie inteligentny system \nskanowania włosów, który uzupełnia ich \nstrukturę o brakujące składniki.",
  },
  {
    img: detox,
    name: "DETOX",
    path: "detox",
    content:
      "Linia trychologiczna, która radzi sobie \nz problematyczną skórą głowy, a także zwalcza \nschorzenia takie jak łupież przewlekły, łuszczyca \noraz łojotokowe zapalenie skóry głowy. ",
  },
  {
    img: alquimia,
    name: "ALQUIMIA",
    path: "alquimia",
    content:
      "Linia zabiegów personalizowanych \nz ampułkami. Każdy profesjonalista może \nstać się kreatywnym alchemikiem, tworząc \nskuteczne i ekskluzywne rozwiązania, które \npodkreślają piękno i zdrowie włosów. ",
  },
  {
    img: thefirst,
    name: "THE FIRST",
    path: "the-first",
    content: "Zawiera pierwszy szampon w proszku oraz \nnajszybsze prostowanie na świecie, dzięki \ninnowacyjnemu szamponowi prostującemu. \nZaoszczędzi czas w Twoim salonie, \noptymalizując pracę, zwiększając \nrozwój biznesu i rentowność. ",
  },
  {
    img: lovely,
    name: "LOVELY",
    path: "lovely",
    content:
      "Lovely Straightening to organiczny system \nprostowania włosów za pomocą kwasów. \nDziała poprzez wygładzanie dzięki interakcji \nmolekularnej między wiązaniami w strukturze \nwłosów. Może być stosowany u kobiet w ciąży, \noraz na włosy rozjaśniane.",
  },
];